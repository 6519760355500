const listOfContent = {
  "How does ZAKchat work?": {
    tags: [
      "safe",
      "secure",
      "private",
      "donate",
      "100",
      "donation",
      "information",
      "info",
      "balance",
    ],
    content: {
      "How does ZAKchat work?": [
        {
          type: "paragraph",
          text: "ZAKchat is a Zakat calculator like no other. You speak with ZAK - your personal Zakat assistant - and through carefully asked questions you will quickly and accurately arrive at your Zakat liability.",
        },
        {
          type: "paragraph",
          text: "By the end of the conversation, you’ll have the choice to choose from a number of our partner charities (e.g. Islamic Relief, Penny Appeal etc.) to donate your Zakat to.",
        },
      ],
      "Is my data safe, secure and private?": [
        {
          type: "paragraph",
          text: "Yes, all your data is safely secured and not shared with any 3rd parties",
        },
      ],
      "Will my donation go 100% to the charity I donate to?": [
        {
          type: "paragraph",
          text: "Yes, that is 100% correct.",
        },
      ],
      "What information do I need to have handy when using ZAKchat?": [
        {
          type: "paragraph",
          text: "You’ll need to have up to date figures of your bank and cash balances, weight of any gold and silver and any pensions and investments (e.g. stocks, cryptocurrencies).",
        },
      ],
    },
  },
  "What is Zakat?": {
    tags: ["what", "is", "zakat"],
    content: {
      "What is Zakat?": [
        {
          type: "paragraph",
          text: "Zakat is one of the five pillars of Islam. These pillars strengthen the faith and character of the individual and build up the collective commitment and contribution of those who believe. The five pillars, individually and taken together, provide the foundation for Islam to flourish as a real commitment to God that inspires believers to strive for prosperity, harmony and justice for all.",
        },
        {
          type: "paragraph",
          text: "By paying the Zakat that is due, for the purposes which we believe God has decreed, we are fulfilling our obligation as Muslims. Some of the outcomes of local Zakat distribution are:",
        },
        {
          type: "list",
          items: [
            "Those most in need in our community will feel a sense of belonging, their situation can improve and they can strive to become Zakat payers themselves.",
            "Our community will improve in its understanding of faith and in its participation in wider society, while external perceptions of Islam and Muslims improve.",
            "This will enable Muslims to increasingly be faithful, successful and contribute to society for the benefit of all, and will allow all of us to reap the rewards both in this life and the hereafter, God willing.",
          ],
        },
      ],
    },
  },
  "Who has to pay Zakat?": {
    tags: ["pay", "who"],
    content: {
      "Who has to pay Zakat?": [
        {
          type: "paragraph",
          text: "Anyone who is a Muslim, adult (has reached puberty) and is sane, is expected to give Zakat if they have the minimum amount of wealth, which is known as the Nisab. The Nisab was set by the Prophet Muhammad (peace be upon him) at a rate equivalent to 87.48 grams of gold and 612.36 grams of silver.",
        },
        {
          type: "paragraph",
          text: "If you’re not up to date on your gold and silver values then don’t worry, this simply means about £3,664.54 (gold Nisab) and £286.34 (silver Nisab) as of February 2020 respectively. Check out our Nisab Rate page which is regularly updated with the latest amounts.",
        },
      ],
    },
  },
  "When should you pay Zakat?": {
    tags: ["should", "when", "date", "nisab"],
    content: {
      "When should you pay Zakat?": [
        {
          type: "paragraph",
          text: "Zakat is due immediately upon your Zakat anniversary. If you’re not sure when this is or you’re paying for the first time, simply work out the day you first owned wealth over and above the Nisab threshold (if you don’t know the exact date you can estimate). Once you’ve paid your Zakat once this then becomes your Zakat anniversary.",
        },
        {
          type: "paragraph",
          text: "Zakat can be pre-paid before your Zakat anniversary. This is to encourage people to increase in worship and make the most of blessed times like Ramadan or the first ten days of Dhul Hijjah. If you do pay early, you still need to work out your Zakat due on your Zakat anniversary to make sure you’ve paid the right amount.",
        },
      ],
    },
  },
  "Do you have to pay Zakat on pension and retirement schemes?": {
    tags: ["pension", "retirement", "scheme"],
    content: {
      "Do you have to pay Zakat on pension and retirement schemes?": [
        {
          type: "paragraph",
          text: "If you have a defined contribution scheme pension, Zakat is due on specific assets in the fund. If you don’t know which assets, you can use our easy to use online Calculator now. Simply enter the type of pension you have and its current value in the ‘My Value’ section and the Calculator will do the rest.",
        },
        {
          type: "paragraph",
          text: "Not every pension is Zakatable. Defined benefit schemes (also known as final salary and career average pensions) are not subject to Zakat. However, defined contribution schemes are Zakatable.",
        },
      ],
    },
  },
  "How do you work out Zakat on stocks and shares?": {
    tags: ["stocks", "stock", "share", "shares", "nisab"],
    content: {
      "How do you work out Zakat on stocks and shares?": [
        {
          type: "paragraph",
          text: "If you own stocks, shares and/or private equity investments, Zakat is due on these investments. If you plan to resell shares, Zakat is payable, at their current value. If you purchase shares as long-term investments for dividend income, you may pay Zakat using a standard rate of 25%. Calculate 25% of the current market value of your portfolio. This can be easily worked out using ZAKchat.",
        },
      ],
      "What is the Zakat on gold and silver?": [
        {
          type: "paragraph",
          text: "Gold and silver in whichever form they are in (jewellery, coins, or ingots etc.) are all subject to Zakat.",
        },
        {
          type: "paragraph",
          text: "If you own personal items made from a mixture of metals, then the gold/silver is liable to Zakat if the gold/silver content can be distinguished and ascertained.",
        },
        {
          type: "paragraph",
          text: "Some scholars are of the opinion that mixed metals are only liable to Zakat if half or more of the metal is gold or silver.",
        },
      ],
      "How much gold/silver do you have to own before Zakat is payable?": [
        {
          type: "paragraph",
          text: "This question can be answered in the context of your net assets. You then need to establish whether your net assets equal or exceed the Nisab threshold. If you exceed the Nisab threshold then Zakat is due on all your wealth at 2.5%.",
        },
        {
          type: "paragraph",
          text: "Zakat is due on two2 types of precious metals – gold and silver.",
        },
        {
          type: "paragraph",
          text: "Gold and silver are used as the basis for calculating your Nisab regardless of currency. This is the threshold of wealth you need to own before Zakat is due.",
        },
        {
          type: "paragraph",
          text: "The gold Nisab should be used on gold assets. If there is a mixture of gold and silver assets, the silver Nisab should be used.",
        },
      ],
      "How do I calculate the value of my gold/silver?": [
        {
          type: "paragraph",
          text: "Your local gold jeweller can tell you the current value of your gold assets.",
        },
        {
          type: "paragraph",
          text: "They can do this in two ways:",
        },
        {
          type: "list",
          items: [
            "They can give you the current value they would buy your gold at if you were to sell it;",
            "Or they can give you the weight of the gold and you can calculate the value using today’s live gold price in grams – this is known as the universal measurement. This is perhaps most suitable to ensure 100% accuracy.",
          ],
        },
      ],
      "Personal use ornaments": [
        {
          type: "paragraph",
          text: "Items containing gold or silver purchased for personal use as ornaments are Zakatable. This includes ornaments used for house decoration and utensils (such as silver spoons etc).",
        },
      ],
      "Gold or Silver designated for personal use": [
        {
          type: "paragraph",
          text: "There are differences of opinion regarding whether or not gold and silver jewellery that is kept for personal use should be exempt from Zakat. The preferred opinion is to that zakat is not applicable on gold and silver that is being used.",
        },
      ],
      "Other precious metals, stones and mixed assets": [
        {
          type: "paragraph",
          text: "Other precious metals like platinum, diamond and precious stones such as diamonds and rubies are not subject to Zakat.",
        },
      ],
    },
  },
  "Why do the scholars differ in relation the wealth of children?": {
    tags: [
      "why",
      "do",
      "scholars",
      "scholar",
      "differ",
      "wealth",
      "wealth of children",
      "children",
      "nisab",
    ],
    content: {
      "Why do the scholars differ in relation the wealth of children?": [
        {
          type: "paragraph",
          text: "The three schools of jurisprudence other than the Hanafi school state that Zakat should be paid on qualifying wealth owned by the orphan, child and the insane.",
        },
        {
          type: "paragraph",
          text: "The three schools of jurisprudence consider the ownership of wealth reaching the Nisab as the driving factor behind the Zakat obligation. Furthermore, the child, insane and orphan’s wealth will also benefit from the purification of wealth due to the payment of Zakat.",
        },
        {
          type: "paragraph",
          text: "The Hanafi school considers the element of worship in Zakat and therefore opines that Zakat is not binding on the orphan, child and insane. Since these individuals are not obliged to perform worship, they are not obliged to pay Zakat. However, if a person has intermittent sanity, they will be required to pay Zakat. Only a person with continuous insanity and mental incapacity is exempt from Zakat .",
        },
        {
          type: "paragraph",
          text: "The parent/guardian will be responsible to pay Zakat on the wealth of the orphan, child and insane. They can pay from the wealth of these individuals.",
        },
        {
          type: "paragraph",
          text: "And Allah knows best!",
        },
      ],
    },
  },
  "Why are liabilities deductible?": {
    tags: ["deductible", "liabilities", "liability"],
    content: {
      "Why are liabilities deductible": [
        {
          type: "paragraph",
          text: "Not all liabilities are deductible, only certain debts are deductible. Worship has not been enjoined to bring difficulty to man, rather, worship is there to accelerate man to Allah whilst considering his needs, circumstances and life. Thus, Ṣalāh has only been enjoined five times a day with most prayers in the evening when a person is free from the chores of the day. Ḥajj is only obligatory if a person can safely reach Makkah and bear all the costs. Fasting has been enjoined only for the day with strong encouragement in the Sunnah to eat right up to the break of dawn and to break the fast immediately upon sunset. In the same manner, a person who has wealth can first deduct his liabilities and debts from his total before determining how much wealth he must pay Zakat upon.",
        },
        {
          type: "paragraph",
          text: "One of the reasons why Zakat is not due on life essentials such as food, shelter and clothes is that these items ward off harm from oneself. They are essentials to survive and they preserve life. The repayment of debt is also considered an essential of life; repayment of debts protect a person from punitive measures and legal action. ",
        },
        {
          type: "paragraph",
          text: "Therefore, Zakat is not due on immediate debts just as Zakat is not due on other life essentials.",
        },
        {
          type: "paragraph",
          text: "And Allah knows best!",
        },
      ],
    },
  },
  "Can I pay my Zakat in advance?": {
    tags: ["advance"],
    content: {
      "Can I pay my Zakat in advance?": [
        {
          type: "paragraph",
          text: "Once the zakatable wealth has reached the nisab value, a person can pay Zakat in advance for any amount of coming years. This is the opinion of the Hanafi school of jurisprudence.",
        },
        {
          type: "paragraph",
          text: "The most famous opinion for the Malikis is that Zakat can be given only one month in advance.",
        },
        {
          type: "paragraph",
          text: "According to the Shafi’i school of jurisprudence, Zakat can be paid in advance for up to only one lunar calendar year.",
        },
        {
          type: "paragraph",
          text: "As for the Hanbalis, the correct opinion is that Zakat can be given in advance for a period of up to two years only.",
        },
      ],
      "Why are defined benefit schemes not Zakatable?": [
        {
          type: "paragraph",
          text: "Zakat is not due on a defined benefit scheme due to the following reasons:",
        },
        {
          type: "paragraph",
          text: "The accumulated assets in the pension fund are not owned by the employee",
        },
        {
          type: "paragraph",
          text: "The employee owns the rights to receive a pension, but not the assets being accumulated to pay those pension obligations. A defined benefit pension is typically run, on behalf of the employer by the Board of Trustees, who are responsible for all aspects of the scheme. Thus, the fundamental condition of ownership is not found. Therefore, the employee is not responsible to pay Zakat on the pension fund as he does not own the contributions.",
        },
      ],
      "The employer bears the performance risk of a defined benefit scheme.": [
        {
          type: "paragraph",
          text: "In a defined benefit scheme, the investment and performance risk of the fund is borne by the employer. The employee, on the other hand, is entitled to a secure income after retirement by virtue of membership to a defined benefit scheme. Therefore, Zakat is not binding on the employee as he does not bear performance risk of the fund.",
        },
      ],
      "Salary deductions are not possessed by the employee": [
        {
          type: "paragraph",
          text:
            "In a defined benefit scheme, salary deductions are never possessed by the employee.\n" +
            "The salary is deducted from his wages prior to possession. The salary remains a deferred salary owed to him. Zakat is not binding on unpossessed deferred salaries.\n",
        },
      ],
      "The scheme administrator is not an agent of the employee and nor is he delegated by the employee":
        [
          {
            type: "paragraph",
            text: "In a defined benefit scheme, the employee does not delegate or select the scheme administrator. Instead, the scheme administrator acts on behalf of the Board of Trustees to manage the defined benefit scheme without any form of delegation or Wakalah (agency) from the employee.",
          },
          {
            type: "paragraph",
            text: "Therefore, possession and access are never realised for the employee. As a consequence, Zakat does not become obligatory on the employee.",
          },
        ],
      "Is Zakat due on money saved for a holiday?": [
        {
          type: "paragraph",
          text: "Cash savings are Zakatable even if it is for a holiday. However, if there are any obligations or outstanding liabilities as a result of the booking, that amount can be deducted in.",
        },
        {
          type: "paragraph",
          text: "And Allah knows best!",
        },
      ],
      "Is Zakat due on money saved for Hajj?": [
        {
          type: "paragraph",
          text: "All savings are Zakatable. However, if any debt has been incurred and is outstanding, such as outstanding booking payments, such debts can be deducted from one’s Zakat calculation.",
        },
        {
          type: "paragraph",
          text: "And Allah knows best!",
        },
      ],
      "Is there any Zakat on unlawful earnings?": [
        {
          type: "paragraph",
          text: "No, Zakat paid on unlawful earnings will not be pure. There is no Zakat on any kind of unlawful wealth.",
        },
      ],
    },
  },
};

export default {
  listOfContent,
};
