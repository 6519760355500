<template>
  <div class="zch-faq">
    <Navigation />
    <div class="zch-faq-search">
      <img src="../assets/logo.svg" alt="" />
      <h1>How can we help you?</h1>

      <input
        v-model="searchFilter"
        @keyup="filterResults"
        type="text"
        placeholder="Type keywords to find answers"
      />

      <p>
        You can also browse the topics below to find what you are looking for.
      </p>
    </div>
    <div class="zch-faq-container">
      <div class="list-of-content">
        <h2>FAQ</h2>
        <div
          v-for="(item, key) in listOfContent"
          v-bind:key="key"
          class="item"
          :class="listItemClass(key)"
          @click="selectContentItem(item, key)"
        >
          {{ key }}
        </div>
      </div>
      <div class="info">
        <div
          v-for="(item, itemKey) in selectedContentItem.content"
          v-bind:key="itemKey"
          class="item"
        >
          <h2
            :class="itemHeadingClass(itemKey)"
            @click="expandItemContent(itemKey)"
          >
            <img
              v-if="isItemDisplayed(itemKey)"
              src="../assets/arrow-active.svg"
              alt=""
            />
            <img v-else src="../assets/arrow-inactive.svg" alt="" />
            <span :class="itemHeadingClass">
              {{ itemKey }}
            </span>
          </h2>
          <div v-for="(subItem, subItemKey) in item" v-bind:key="subItemKey">
            <p v-if="isItemDisplayed(itemKey) && isSubItemParagraph(subItem)">
              {{ subItem.text }}
            </p>
            <ul v-else-if="isItemDisplayed(itemKey) && isSubItemList(subItem)">
              <li v-for="(item, key) in subItem.items" v-bind:key="key">
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "@/layouts/Navigation";
import FaqSvc from "@/service/faq";

export default {
  name: "FAQ",
  components: {
    Navigation,
  },
  data() {
    return {
      listOfContent: FaqSvc.listOfContent,
      selectedContentItem:
        FaqSvc.listOfContent[Object.keys(FaqSvc.listOfContent)[0]],
      selectedContentItemKey: Object.keys(FaqSvc.listOfContent)[0],
      searchFilter: "",
    };
  },
  methods: {
    isItemDisplayed(item) {
      return this.selectedContentItem["displayState"]
        ? this.selectedContentItem["displayState"][item]
        : false;
    },

    isSubItemParagraph(subItem) {
      return subItem.type === "paragraph";
    },

    isSubItemList(subItem) {
      return subItem.type === "list";
    },

    selectContentItem(item, key) {
      this.selectedContentItem = item;
      this.selectedContentItemKey = key;
      this.predefineContentItems();
    },

    expandItemContent(itemKey) {
      this.selectedContentItem["displayState"][itemKey] =
        !this.selectedContentItem["displayState"][itemKey];
      this.$forceUpdate();
    },

    predefineContentItems() {
      this.selectedContentItem["displayState"] = Object.keys(
        this.selectedContentItem.content
      ).reduce((a, b) => {
        a[b] = false;
        return a;
      }, {});
    },

    itemHeadingClass(item) {
      return this.isItemDisplayed(item) ? "active" : "";
    },

    listItemClass(key) {
      return key === this.selectedContentItemKey ? "active" : "";
    },

    filterResults() {
      const searchKeywords = this.searchFilter.toLowerCase().split(" ");

      if (
        this.searchFilter.length === 0 &&
        this.selectedContentItemKey === ""
      ) {
        return this.resetSelection();
      }

      for (let i in this.listOfContent) {
        if (Object.prototype.hasOwnProperty.call(this.listOfContent, i)) {
          for (let j = 0, len = searchKeywords.length; j < len; j++) {
            if (this.listOfContent[i].tags.includes(searchKeywords[j])) {
              this.selectContentItem(this.listOfContent[i], "");
            }
          }
        }
      }
    },

    resetSelection() {
      this.selectedContentItem =
        FaqSvc.listOfContent[Object.keys(FaqSvc.listOfContent)[0]];
      this.selectedContentItemKey = Object.keys(FaqSvc.listOfContent)[0];
    },
  },
  mounted() {
    this.predefineContentItems();
  },
};
</script>

<style lang="scss" scoped>
.zch-faq {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding: 24px;
  background: url(../assets/background-logo.png) no-repeat,
    radial-gradient(circle at 50% 50%, #fbfffe, #f6fffc 151%) no-repeat;
  background-size: contain;
  background-position-x: right;
  margin-bottom: 50px;

  &-search {
    width: 80%;
    padding: 50px 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;

    @media screen and (max-width: 992px) {
      width: 100%;
      padding: 50px 20px;
    }

    img {
      max-width: 70px;
    }

    h1 {
      font-size: 2rem;
      font-family: Muli, sans-serif;
      color: $color-montecarlo;
      text-align: center;
    }

    input {
      width: 100%;
      border-radius: 27px;
      border: solid 1px $color-montecarlo;
      background-color: #e6f9f3;
      padding: 15px 20px;
      color: $color-montecarlo;
      font-family: Muli, sans-serif;
    }

    p {
      font-family: Muli, sans-serif;
      color: $color-dove-gray;
      text-align: center;
    }
  }

  &-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 0 5%;

    @media screen and (max-width: 992px) {
      flex-direction: column-reverse;
    }

    .list-of-content {
      width: calc(28% - 20px);
      font-family: Muli, sans-serif;
      padding: 10px;
      border-right: 1px solid #{$color-dove-gray}22;

      @media screen and (max-width: 992px) {
        border: none;
        padding: 0;
        width: 100%;
        margin-top: 100px;
      }

      h2 {
        color: $color-montecarlo;
        font-weight: bold;
        font-size: 3.5rem;
        margin: 0 0 1px 0;

        @media screen and (max-width: 992px) {
          display: none;
        }
      }

      .item {
        display: flex;
        align-items: center;
        padding: 10px 0 10px 0;
        font-weight: bold;
        transition: 500ms;
        cursor: pointer;

        &.active {
          color: $color-montecarlo;
        }

        &:hover {
          opacity: 0.5;
        }

        &:not(:last-child) {
          border-bottom: 1px solid #{$color-dove-gray}22;
        }
      }
    }

    .info {
      width: 70%;

      @media screen and (max-width: 992px) {
        width: 100%;
      }

      .item {
        border-bottom: 1px solid #{$color-dove-gray}22;

        h2 {
          display: flex;
          align-items: center;
          font-family: Muli, sans-serif;
          font-size: 18px;
          font-weight: bold;
          line-height: 2.88;
          color: $color-dove-gray;
          margin-bottom: 0;
          cursor: pointer;
          transition: opacity 500ms;

          &:hover {
            opacity: 0.7;
          }

          &.active {
            color: $color-montecarlo;
          }

          span {
            margin-left: 10px;
          }
        }

        p {
          margin-top: 0;
          font-family: Muli, sans-serif;
          line-height: 1.38;
          font-size: 16px;
        }

        ul {
          list-style: none;

          li {
            font-family: Muli, sans-serif;
            line-height: 1.38;
            font-size: 16px;

            &::before {
              content: "\2022";
              color: $color-montecarlo;
              font-weight: bold;
              display: inline-block;
              width: 1em;
              margin-left: -1em;
            }
          }
        }
      }
    }
  }
}

::placeholder {
  color: $color-montecarlo;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $color-montecarlo;
}
</style>
